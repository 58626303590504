import { useFormState } from 'react-dom';

import {
  ConformActionErrorState,
  ConformActionState,
  ConformActionSuccessState,
} from '@/lib/form-action/type';

export type UseConformActionInputAction<Data = never> = (
  state: ConformActionState<Data>,
  payload: FormData,
) => ConformActionState<Data> | Promise<ConformActionState<Data>>;

export type UseConformActionInputOptions<Data = never> = {
  initialState?: ConformActionState<Data>;
  onSuccess?: (newState: ConformActionSuccessState<Data>) => void;
  onError?: (newState: ConformActionErrorState) => void;
  permalink?: string;
};

const useConformAction = <Data = never>(
  action: UseConformActionInputAction<Data>,
  options: UseConformActionInputOptions<Data> = {},
): [
  state: Awaited<ConformActionState<Data>>,
  dispatch: (payload: FormData) => void,
] => {
  const [state, formAction] = useFormState(
    async (prevState: ConformActionState<Data>, formData: FormData) => {
      const newState = await action(prevState, formData);
      if (newState?.status === 'success' && options.onSuccess) {
        options.onSuccess(newState as ConformActionSuccessState<Data>);
      } else if (newState?.status === 'error' && options.onError) {
        options.onError(newState);
      }
      return newState;
    },
    options.initialState,
    options.permalink,
  );
  return [state, formAction];
};

export default useConformAction;
