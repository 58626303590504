import { FieldMetadata, getInputProps } from '@conform-to/react';
import { useTranslations } from 'next-intl';
import { ComponentProps } from 'react';

import FieldWithLabelAndError from '@/app/_components/conform/field-with-label-and-error';
import { Input, InputContainer } from '@/app/_components/ui/input';

export const getInputSuffix = ({
  percent,
  currency,
  suffix,
}: {
  percent?: boolean;
  currency?: boolean;
  suffix?: string;
}) => {
  if (percent) return '%';
  if (currency) return '€';
  if (suffix) return suffix;
  return undefined;
};

export type InputFieldProps = {
  meta: FieldMetadata<string | File | number | boolean>;
  type: Parameters<typeof getInputProps>[1]['type'];
  label?: string;
  errorParams?: Record<string, number | string>;
  rawLabel?: boolean;
  percent?: boolean;
  currency?: boolean;
  suffix?: string;
} & ComponentProps<typeof Input>;

const InputField = ({
  meta,
  type,
  className,
  label,
  key,
  errorParams,
  placeholder,
  rawLabel,
  percent,
  currency,
  suffix,
  ...rest
}: InputFieldProps) => {
  const t = useTranslations();
  const invalid = !!meta.errors;
  const haveContainer = percent ?? currency;

  if (type === 'hidden') {
    return (
      <Input
        {...getInputProps(meta, { type, ariaAttributes: true })}
        key={key}
        {...rest}
      />
    );
  }

  return (
    <FieldWithLabelAndError
      id={`${meta.formId}-${meta.name}`}
      label={label}
      rawLabel={rawLabel}
      required={meta.required}
      errors={meta.errors}
      className={className}
      errorParams={errorParams}
    >
      <InputContainer
        suffix={getInputSuffix({ percent, currency, suffix })}
        invalid={invalid}
      >
        <Input
          {...getInputProps(meta, { type, ariaAttributes: true })}
          // Destructuring `key` is required to prevent an error in the console
          key={key}
          invalid={invalid}
          placeholder={placeholder ?? t('common.form.placeholder')}
          className={haveContainer ? 'pr-7' : undefined}
          {...rest}
        />
      </InputContainer>
    </FieldWithLabelAndError>
  );
};

export default InputField;
