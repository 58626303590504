import { clientSideSchema } from './types';

// Need to explicitely pass each public variable for bundle substitution
// https://nextjs.org/docs/app/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
const parsedEnvVariables = clientSideSchema.safeParse({
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
  NEXT_PUBLIC_LOGGER: process.env.NEXT_PUBLIC_LOGGER,
  NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_MFA_CODE_LENGTH: process.env.NEXT_PUBLIC_MFA_CODE_LENGTH,
  NEXT_PASS_CULTURE_SELLING_CHANNEL_ID:
    process.env.NEXT_PASS_CULTURE_SELLING_CHANNEL_ID,
  NEXT_PUBLIC_MAPS_API_KEY: process.env.NEXT_PUBLIC_MAPS_API_KEY,
});

if (!parsedEnvVariables.success) {
  // This will be run during build time
  // eslint-disable-next-line no-console
  console.error({
    message: 'Invalid environment values',
    error: parsedEnvVariables.error,
  });
  throw new Error('Invalid environment values');
}

const env = parsedEnvVariables.data.NEXT_PUBLIC_ENVIRONMENT;

const clientConfig = {
  env,
  isDeployedEnv: ['production', 'test'].includes(env),
  logger: {
    level: parsedEnvVariables.data.NEXT_PUBLIC_LOG_LEVEL,
    type: parsedEnvVariables.data.NEXT_PUBLIC_LOGGER,
  },
  passCultureSellingChannelId:
    parsedEnvVariables.data.NEXT_PASS_CULTURE_SELLING_CHANNEL_ID,
  sentry: {
    isEnabled: parsedEnvVariables.data.NEXT_PUBLIC_SENTRY_ENABLED,
    dsn: parsedEnvVariables.data.NEXT_PUBLIC_SENTRY_DSN,
  },
  mfaCodeLength: parsedEnvVariables.data.NEXT_PUBLIC_MFA_CODE_LENGTH,
  mapsApiKey: parsedEnvVariables.data.NEXT_PUBLIC_MAPS_API_KEY,
} as const;

export default clientConfig;
