'use client';

import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';

import { useEffect } from 'react';

import ErrorContent from '@/app/_components/errors/error-content';
import { Button } from '@/app/_components/ui/button';

interface ErrorPageProps {
  error: Error & { digest?: string };
  reset: () => void;
}

// Here it's the global error, whenever an error is catched outside a layout which has an error.tsx file, we will arrive here at the root layout error boundary
const ErrorPage = ({ reset, error }: ErrorPageProps) => {
  const t = useTranslations();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ErrorContent error={error}>
      <h2 className="text-xl text-destructive">{t('error.errorOccured')}</h2>

      <Button onClick={() => reset()}>{t('errorBoundary.tryAgain')}</Button>
    </ErrorContent>
  );
};

export default ErrorPage;
