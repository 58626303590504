import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from '@/app/_components/ui/button';

interface ErrorDetailsProps {
  error: Error & { digest?: string };
}

const ErrorDetails = ({ error }: ErrorDetailsProps) => {
  const [seeDetails, setSeeDetails] = useState<boolean>(false);
  const t = useTranslations();

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        onClick={() => setSeeDetails(!seeDetails)}
      >
        {t(
          seeDetails ? 'errorBoundary.hideDetails' : 'errorBoundary.seeDetails',
        )}
      </Button>

      {seeDetails ? (
        <div className="w-3/4 overflow-auto rounded-md border border-destructive p-4 text-xs text-destructive">
          <pre>{error.stack}</pre>
        </div>
      ) : null}
    </>
  );
};

export default ErrorDetails;
