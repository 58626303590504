'use client';

import { ReloadIcon } from '@radix-ui/react-icons';

import { Button, ButtonProps } from '@/app/_components/ui/button';

import { cn } from '@/lib/utils';

interface ActionButtonProps extends Omit<ButtonProps, 'asChild'> {
  loading?: boolean;
}

const ActionButton = ({
  disabled,
  className,
  children,
  loading,
  type = 'button',
  onClick,
  ...rest
}: ActionButtonProps) => {
  return (
    <Button
      className={cn('flex flex-row items-center gap-x-2', className)}
      disabled={disabled ?? loading ?? !onClick}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <ReloadIcon
          data-testid="loading-icon"
          className="size-4 animate-spin"
        />
      ) : null}

      {children}
    </Button>
  );
};

export default ActionButton;
