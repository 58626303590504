import { z } from 'zod';

import {
  stringRequiredSchema,
  loginRequiredSchema,
} from '@/lib/validation/zod';

export const loginSchema = z.object({
  login: loginRequiredSchema,
  password: stringRequiredSchema,
});

export type LoginFormPayload = z.infer<typeof loginSchema>;
