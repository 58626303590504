import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { PropsWithChildren } from 'react';

import ErrorDetails from '@/app/_components/errors/error-details';
import clientConfig from '@/lib/config/client-config';

interface ErrorProps extends PropsWithChildren {
  error: Error & { digest?: string };
}

const ErrorContent = ({ error, children }: ErrorProps) => {
  return (
    <div className="w-full">
      <div className="flex size-full flex-col items-center justify-center gap-4">
        <ExclamationTriangleIcon className="size-10 text-destructive" />

        {children}

        {!clientConfig.isDeployedEnv ? <ErrorDetails error={error} /> : null}
      </div>
    </div>
  );
};

export default ErrorContent;
