import Link from 'next/link';
import * as React from 'react';

import { cn } from '@/lib/utils';

const cardClassName =
  'rounded-xl border border-card-foreground bg-card text-card-foreground';

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn(cardClassName, className)} {...props}>
    {children}
  </div>
));
Card.displayName = 'Card';

const CardLink = ({
  className,
  children,
  ...rest
}: React.ComponentProps<typeof Link> & { className?: string }) => (
  <Link
    className={cn(
      cardClassName,
      'transition hover:border-primary-200',
      className,
    )}
    {...rest}
  >
    {children}
  </Link>
);
CardLink.displayName = 'CardLink';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-1.5 p-3', className)}
    {...props}
  >
    {children}
  </div>
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'font-medium leading-none tracking-tight text-primary-500',
      className,
    )}
    {...props}
  >
    {children}
  </h3>
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  >
    {children}
  </p>
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('p-3 pt-0', className)} {...props}>
    {children}
  </div>
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center p-3 pt-0', className)}
    {...props}
  >
    {children}
  </div>
));
CardFooter.displayName = 'CardFooter';

export {
  Card,
  CardLink,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
