'use client';

import { FormProvider, getFormProps } from '@conform-to/react';
import {
  CheckCircledIcon,
  EnvelopeClosedIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons';

import { useTranslations } from 'next-intl';
import { ReactElement } from 'react';

import { login } from '@/app/[locale]/(identity)/login/actions';
import { loginSchema } from '@/app/[locale]/(identity)/login/type';
import ConformSubmitButton from '@/app/_components/conform/conform-submit-button';
import FormGlobalError from '@/app/_components/conform/form-global-error';
import InputField from '@/app/_components/conform/input-field';
import OAuth2SigninSection from '@/app/_components/o-auth-two-signin-section';

import Typo from '@/app/_components/typo';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/app/_components/ui/alert';
import { CardContent, CardFooter } from '@/app/_components/ui/card';

import { useConformForm } from '@/app/_hooks/use-conform-form';
import { AuthErrors } from '@/lib/auth/errors';
import { From } from '@/lib/router/from';
import Routes from '@/lib/router/routes';
import { ProviderInformation } from '@/lib/router/types';
import { TFunction } from '@/lib/translation/config';
import { Link } from '@/lib/translation/hooks';

const getFromAlert = (
  from: string | null,
  t: TFunction,
): { icon: ReactElement; title: string; description: string } | null => {
  switch (from) {
    case From.Signup:
      return {
        icon: <EnvelopeClosedIcon />,
        title: t('auth.emailVerification.title'),
        description: t('auth.emailVerification.description'),
      };

    case From.AskPasswordReset:
      return {
        icon: <EnvelopeClosedIcon />,
        title: t('auth.passwordResetting.title'),
        description: t('auth.passwordResetting.description'),
      };

    case From.ResetPassword:
      return {
        icon: <CheckCircledIcon />,
        title: t('auth.passwordResetSuccess.title'),
        description: t('auth.passwordResetSuccess.description'),
      };

    default:
      return null;
  }
};

const getWarnAlert = (
  warn: string | null,
  t: TFunction,
): { description: string } | null => {
  switch (warn) {
    case AuthErrors.IDENTITY_ALREADY_EXISTS:
      return {
        description: t('auth.error.identityAlreadyExists'),
      };
    case AuthErrors.DEACTIVATED_ACCOUNT:
      return {
        description: t('auth.error.deactivatedAccount'),
      };
    case AuthErrors.INVALID_ACCOUNT:
      return {
        description: t('auth.error.invalidEmailOrPassword'),
      };

    default:
      return null;
  }
};

interface Props {
  searchParams: {
    from: string | null;
    warn: string | null;
  };
  activeProviders: ProviderInformation[];
}

const LoginForm = ({ searchParams, activeProviders }: Props) => {
  const t = useTranslations();

  const { lastResult, action, form, fields } = useConformForm({
    action: login,
    schema: loginSchema,
  });

  const fromAlert = getFromAlert(searchParams.from, t);
  const warn = getWarnAlert(searchParams.warn, t);

  return (
    <FormProvider context={form.context}>
      <form {...getFormProps(form)} action={action} className="w-full">
        <CardContent className="mb-4 flex flex-col gap-y-4">
          {fromAlert ? (
            <Alert variant="success">
              {fromAlert.icon}
              <AlertTitle>{fromAlert.title}</AlertTitle>
              <AlertDescription>{fromAlert.description}</AlertDescription>
            </Alert>
          ) : null}

          {warn ? (
            <Alert variant="warn">
              <ExclamationTriangleIcon />

              <AlertTitle>{t('error.errorOccured')}</AlertTitle>
              <AlertDescription>{warn.description}</AlertDescription>
            </Alert>
          ) : null}

          <FormGlobalError state={lastResult} />

          <InputField
            meta={fields.login}
            type="email"
            label={t('auth.login')}
            placeholder="john.doe@example.com"
            autoComplete="username"
          />

          <InputField
            meta={fields.password}
            type="password"
            placeholder="********"
            label={t('auth.password')}
            autoComplete="current-password"
          />
        </CardContent>

        <CardFooter className="flex flex-col gap-y-10">
          <div className="flex w-full flex-col items-center gap-y-4">
            <ConformSubmitButton
              data-testid="signIn"
              formId={form.id}
              className="w-full"
            >
              {t('auth.signIn')}
            </ConformSubmitButton>

            <p>
              <Typo className="text-muted-foreground">
                {t('auth.passwordForgotten')}
              </Typo>
              <Link
                href={Routes.askPasswordReset}
                className="ml-2 text-primary hover:underline"
              >
                {t('auth.resetPassword')}
              </Link>
            </p>
          </div>
          <OAuth2SigninSection activeProviders={activeProviders} />
        </CardFooter>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
