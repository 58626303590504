/* eslint-disable filenames/match-exported */

'use client';

// here we need to user NextLink for providers call
// because api url not starting with locale
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import { useTranslations } from 'next-intl';

import { ProviderInformation } from '@/lib/router/types';

import { cn } from '@/lib/utils';

import { Button } from './ui/button';

interface Props {
  activeProviders: ProviderInformation[];
}

const ProviderLink = ({ route, label, icon }: ProviderInformation) => {
  return (
    <Button
      className="flex flex-row gap-x-4"
      type="button"
      variant="outline"
      asChild
    >
      {/*
        here we need to user NextLink for providers call
        because api url not starting with locale
      */}
      <NextLink key={route} href={route} prefetch={false}>
        {icon}
        {label}
      </NextLink>
    </Button>
  );
};

const OAuth2SigninSection = ({ activeProviders }: Props) => {
  const t = useTranslations();
  if (activeProviders.length === 0) {
    return null;
  }

  return (
    <>
      <span
        className={cn([
          'flex flex-row items-center text-xs uppercase text-slate-600',
          'before:mr-2 before:block before:w-16 before:border-t before:border-slate-300',
          'after:ml-2 after:block after:w-16 after:border-t after:border-slate-300',
        ])}
      >
        {t('auth.signInSSO')}
      </span>
      <div className="flex w-full flex-row flex-wrap justify-center space-x-4">
        {activeProviders.map((provider) => (
          <ProviderLink key={provider.route} {...provider} />
        ))}
      </div>
    </>
  );
};

export default OAuth2SigninSection;
