import Big from 'big.js';

import { safelyMultiplyNumbers } from '@/lib/number';

export const roundAndConvertDecimalToBigInt = (
  value: number | string | undefined | null,
) => {
  if (value) {
    try {
      return BigInt(Big(value.toString()).mul(100).round().toNumber());
    } catch (error) {
      return 0n;
    }
  }
  return 0n;
};

export const convertNumberToStringBigInt = (value: number) =>
  `${safelyMultiplyNumbers(value, 100)}`;

export function convertBigIntToDecimal(value: bigint): number;
export function convertBigIntToDecimal(value: undefined): undefined;
export function convertBigIntToDecimal(
  value: bigint | undefined,
): number | undefined;
export function convertBigIntToDecimal(
  value: bigint | undefined,
): number | undefined {
  return value ? Big(value.toString()).div(100).toNumber() : undefined;
}
