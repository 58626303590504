'use client';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

import { useTranslations } from 'use-intl';

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/app/_components/ui/alert';
import { ConformActionState } from '@/lib/form-action/type';

interface FormGlobalErrorProps {
  state: ConformActionState;
}

const FormGlobalError = ({ state }: FormGlobalErrorProps) => {
  const t = useTranslations();

  if (!state || state.status !== 'error' || !state.messageKey) {
    return null;
  }

  return (
    <Alert variant="destructive">
      <ExclamationTriangleIcon />

      <AlertTitle>{t('error.errorOccured')}</AlertTitle>
      <AlertDescription>
        <p className="flex flex-col text-sm text-destructive">
          {state.messageKeyParams
            ? t(state.messageKey, { ...state.messageKeyParams })
            : t(state.messageKey)}
        </p>
      </AlertDescription>
    </Alert>
  );
};

export default FormGlobalError;
