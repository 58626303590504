'use client';

import { HTMLProps } from 'react';

import FieldErrors from '@/app/_components/conform/field-errors';
import OptionalTag from '@/app/_components/optional-tag';
import Label from '@/app/_components/ui/label';
import { cn } from '@/lib/utils';

interface FieldWithLabelAndErrorProps extends HTMLProps<HTMLDivElement> {
  label?: string;
  errors?: string[];
  errorParams?: Record<string, number | string>;
  rawLabel?: boolean;
}

const FieldWithLabelAndError = ({
  id,
  label,
  required,
  errors,
  className,
  children,
  errorParams,
  rawLabel,
  ...rest
}: FieldWithLabelAndErrorProps) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const requiredLabel = rawLabel || required;
  return (
    <div className={cn('flex flex-col gap-y-2', className)} {...rest}>
      {label ? (
        <Label
          htmlFor={id}
          className={cn('flex items-center text-base')}
          invalid={!!errors}
          required={requiredLabel}
        >
          <span className="grow">
            {label}
            {required && !rawLabel ? <span className="ml-1">*</span> : null}
          </span>
          {!required && !rawLabel ? <OptionalTag /> : null}
        </Label>
      ) : null}

      {children}
      {errors ? (
        <FieldErrors errors={errors} errorParams={errorParams} id={id} />
      ) : null}
    </div>
  );
};

export default FieldWithLabelAndError;
