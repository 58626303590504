import * as React from 'react';

import { cn } from '@/lib/utils';

export const getInputBaseClassNames = ({
  withReadOnly,
}: {
  withReadOnly?: boolean;
} = {}) =>
  cn(
    'flex h-9 w-full rounded-md border border-neutral-300 bg-white px-3 py-1 text-sm  text-primary-800 transition-colors',
    'placeholder:text-muted-foreground',
    'focus-visible:border-primary-500 focus-visible:outline-none',
    // disabled or read-only
    withReadOnly
      ? 'read-only:cursor-not-allowed read-only:border-primary-10 read-only:bg-primary-5 read-only:opacity-50 read-only:focus-visible:border-primary-10'
      : '',
    'disabled:cursor-not-allowed disabled:border-primary-10 disabled:bg-primary-5 disabled:opacity-50 disabled:focus-visible:border-primary-10',
  );

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, invalid, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          getInputBaseClassNames({ withReadOnly: true }),
          {
            'invalid:border-destructive invalid:focus-visible:ring-destructive invalid:placeholder:text-destructive-400 invalid:focus-visible:border-destructive':
              invalid,
          },
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

interface InputContainerProps extends React.PropsWithChildren {
  suffix?: string;
  invalid?: boolean;
}

const InputContainer = ({ children, suffix, invalid }: InputContainerProps) =>
  suffix ? (
    <div className="relative">
      {children}
      <span
        className={cn('absolute right-3 top-1.5', {
          'text-muted-foreground': !invalid,
          'text-destructive-400': invalid,
        })}
      >
        {suffix}
      </span>
    </div>
  ) : (
    children
  );

export { Input, InputContainer };
