import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 truncate',
  {
    variants: {
      variant: {
        primary:
          'border bg-primary-500 border-primary-500 hover:bg-primary-400 hover:border-primary-400 disabled:bg-primary-50 disabled:text-primary-300 disabled:border-primary-50 text-white',
        secondary:
          'border bg-secondary-500 border-secondary-500 text-white hover:bg-secondary-400 disabled:bg-secondary-200 disabled:border-secondary-200',
        destructive:
          'border bg-destructive-500 border-destructive-500 text-white hover:bg-destructive-400 disabled:bg-destructive-200 disabled:border-destructive-200',
        warn: 'border bg-warn-500 border-warn-500 text-white hover:bg-warn-400 disabled:bg-warn-200 disabled:border-warn-200',

        outline:
          'border bg-primary-10 border-primary-300 text-primary-400 hover:bg-primary-50 disabled:bg-primary-10 disabled:text-primary-200 disabled:border-primary-200 hover:text-primary-400  disabled:border',
        'outline-secondary':
          'border bg-secondary-10 border-secondary-500 text-secondary-500 hover:bg-secondary-50 disabled:bg-secondary-10 disabled:text-secondary-200 disabled:border-secondary-200 disabled:border',
        'outline-destructive':
          'border bg-destructive-100 border-destructive-500 text-destructive-500 hover:bg-destructive-50 disabled:bg-destructive-10 disabled:text-destructive-200 disabled:border-destructive-200 disabled:border',

        ghost: 'hover:bg-accent hover:text-accent-foreground',

        link: 'border border-transparent text-primary-200 hover:text-primary-500 hover:bg-primary-50 hover:border-primary-50',
        'link-active':
          'border border-primary-50 text-primary-500 hover:text-primary-500 hover:bg-primary-50 hover:boder-primary-50',
        'link-destructive':
          'border border-transparent text-destructive-500 hover:text-destructive-500 hover:bg-destructive-50 hover:boder-destructive-50',
      },
      size: {
        default: 'h-9 px-5 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
