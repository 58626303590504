import { enUS, fr, Locale as DateFnsLocale } from 'date-fns/locale';
import { notFound } from 'next/navigation';
import { Formats, TranslationValues } from 'next-intl';
import { getRequestConfig } from 'next-intl/server';

export const i18n = {
  defaultLocale: 'fr',
  locales: ['en', 'fr'],
  localePrefix: 'as-needed' as const,
};

export const stringToDateFnsLocale: Record<string, DateFnsLocale> = {
  en: enUS,
  fr,
};

export type Locale = (typeof i18n)['locales'][number];

export type TFunction = (
  key: string,
  values?: TranslationValues,
  formats?: Partial<Formats>,
) => string;

type CreateRequestConfig = Parameters<typeof getRequestConfig>[0];

export const loadLocaleMessage: CreateRequestConfig = async ({
  locale = i18n.defaultLocale,
}) => {
  if (!i18n.locales.includes(locale)) {
    // for specific cases when loadLocaleMessage is called with an invalid `lang` if the provided locale does not exist
    // This should not be necessary except for falsy locales containing a `.`.
    // Indeed, these kind of params are explicitly ignored by the middleware (src/middleware.ts:16).
    notFound();
  }

  return {
    messages: (await import(`./resources/${locale}.json`)).default,
  };
};
