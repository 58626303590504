'use client';

import { useTranslations } from 'next-intl';

import StatusTag from '@/app/_components/status-tag';

const OptionalTag = ({ className }: { className?: string }) => {
  const t = useTranslations();

  return (
    <StatusTag className={className} variant="neutral" size="small">
      {t('common.form.optional')}
    </StatusTag>
  );
};

export default OptionalTag;
