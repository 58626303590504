import { useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';

import { z } from 'zod';

import useConformAction, {
  UseConformActionInputAction,
  UseConformActionInputOptions,
} from './use-conform-action';

// we need this to get the options of Conform useForm hook, until Conform exposes them direclty
// this can break if Conform changes its generics
// we use `Parameters<...>[0]` to extract the type of the first argument of a function,
// `typeof someJSVariable` helps to get from JS land to TS land,
// `someGenericFunction<generic1, generic2, ...>` is there to give context to TS on the type to compute
// eslint-disable-next-line @typescript-eslint/naming-convention
export type Conform_UseFormOptions<Schema extends z.Schema> = Parameters<
  typeof useForm<z.input<Schema>, z.output<Schema>>
>[0];

type UserConformFormInput<
  Schema extends z.Schema,
  Data = never,
  Action = UseConformActionInputAction<Data>,
  ActionOptions = UseConformActionInputOptions<Data>,
> = {
  action: Action;
  actionOptions?: ActionOptions;
  schema: Schema;
  formOptions?: Conform_UseFormOptions<Schema>;
  defaultValue?: Conform_UseFormOptions<Schema>['defaultValue'];
};

// Example use
// const { action, form, fields, lastResult } = useConformForm({
//     action: validateTotp,
//     schema: mfaCodeSchema,
//   });

export const useConformForm = <Schema extends z.Schema, Data = never>({
  action,
  actionOptions = {},
  schema,
  formOptions,
  defaultValue,
}: UserConformFormInput<Schema, Data>) => {
  const [lastResult, conformAction] = useConformAction<Data>(action, {
    ...actionOptions,
    ...(defaultValue ? { initialState: { initialValue: defaultValue } } : {}),
  });

  const [form, fields] = useForm<z.input<Schema>, z.output<Schema>>({
    lastResult,
    // IMPORTANT: onValidate is used to validate the field that triggers it (onBlur, onInput) NOT THE ENTIRE FORM
    // BUT onValidate also prevents form from being submitted to server action if schema is not met
    onValidate: ({ formData }) => {
      return parseWithZod(formData, { schema }); // validates data BEFORE action execution
    },
    constraint: getZodConstraint(schema), // input rules that are applied to the inputs HTML (ex: required)
    // IMPORTANT: shouldValidate et shouldRevalidate only trigger FIELD validation NOT FORM validation
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onInput',
    defaultValue,
    ...formOptions,
  });

  return {
    action: conformAction,
    form,
    fields,
    lastResult,
  };
};
