'use client';

import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

export const getLabelColor = ({
  invalid,
  required,
}: {
  invalid?: boolean;
  required?: boolean;
} = {}) => {
  if (invalid) {
    return 'text-destructive';
  }

  return required ? 'text-primary-400' : 'text-primary-200';
};

export const labelVariants = cva(
  'text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & {
      invalid?: boolean;
      required?: boolean;
    }
>(({ className, invalid, required, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(
      labelVariants(),
      getLabelColor({ required, invalid }),
      className,
    )}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export default Label;
