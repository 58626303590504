/**
 * This file is required by shadcn to be imported in generated components
 * You should not add any functions here
 */
// eslint-disable-next-line filenames/match-exported
import { type ClassValue, clsx } from 'clsx';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

// eslint-disable-next-line import/prefer-default-export
export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export type Instantiable<T, Args extends unknown[]> = new (...args: Args) => T;

export const typedReactMemo: <T>(c: T) => T = memo;
